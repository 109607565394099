import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";
import Sidebar from "../components/atoms/sidebar";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 md:mr-8">
          <p className="border-l-4 border-gray-900 font-serif leading-loose pl-4 text-justify">
            <h2 className="bg-purple-400 text-5xl font-bold inline-block my-8 p-3">
              Somos:
            </h2>
            <p>
              un espacio, cuyo objetivo es difundir información médica de manera
              veraz y confiable, en un lenguaje amigable acerca de los problemas
              que pueden surgir durante el desarrollo infantil. La información
              plasmada en éste portal, está sustentada en artículos científicos,
              cuyas referencias bibliográficas aparecerán al final de cada
              sección en caso de que se precise consultar dichas fuentes.
              Esperamos que éste proyecto sea de su agrado y le resulte útil,
              sea bienvenido.
            </p>
          </p>

          <p className="font-bold mt-4 text-right text-xs uppercase">
            Atentamente
          </p>
          <p className="font-bold mt-4 text-right text-s uppercase">
            El equipo de Neuro Clau.
          </p>
        </div>

        <div className="w-2/3 md:w-1/3">
          <img src={dogIllustration} alt="A dog relaxing" />
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
